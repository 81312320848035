import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'templates',
    loadChildren: () => import('./templates/templates.module').then( m => m.TemplatesPageModule)
  },
  {
    path: 'templates/:id',
    loadChildren: () => import('./templates/templates.module').then( m => m.TemplatesPageModule)
  },
  {
    path: 'documents',
    loadChildren: () => import('./documents/documents.module').then( m => m.DocumentsPageModule)
  },
  {
    path: 'documents-viewer/:id',
    loadChildren: () => import('./documents-viewer/documents-viewer.module').then( m => m.DocumentsViewerPageModule)
  },
  {
    path: 'documents-viewer/:id/:section',
    loadChildren: () => import('./documents-viewer/documents-viewer.module').then( m => m.DocumentsViewerPageModule)
  },
  {
    path: 'print-preview/:id',
    loadChildren: () => import('./print-preview/print-preview.module').then( m => m.PrintPreviewPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
