import { Component } from '@angular/core';
import { AppService } from './services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Home', url: '/home', icon: 'home', permissions: 0 },
    { title: 'Dispatch', url: '/documents', icon: 'mail', permissions:  2 },
    // { title: 'Templates', url: '/templates', icon: 'mail' },
    { title: 'Users', url: '/users', icon: 'person', permissions: 9 },
    //{ title: 'Logout', url: '/login', icon: 'exit', permissions: 0 },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(private app: AppService) {}
}


// {name: 'No Access', permission: null },
// {name: 'Worker', permission: '0' },
// {name: 'Mechanic', permission: '1' },
// {name: 'Dispatch', permission: '2' },
// {name: 'Admin', permission: '9' },