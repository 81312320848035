import { Injectable } from '@angular/core';
import { ToastController, AlertController } from '@ionic/angular';

declare var defaultSettings;


@Injectable({
  providedIn: 'root'
})
export class AppService {
  
  sysData = defaultSettings;
  flags: any;
  
  constructor(public toastController: ToastController, public alertController: AlertController) { 

    this.flags = {
      printPreview: null,
      folders: [
        {id: 1, name: 'Color & Objects', epoch: 1629683964000, type: 'visual', data: null},
        {id: 2, name: 'Shapes', epoch: 1629683964000, type: 'visual', data: null},
      ],
      results: [
        {
          id: 1, folder_id: 1, resolution: '600', width: '800px', height: '600px',
          src: './assets/img/games/test1.jpg', name: 'Test1', directions: 'Which one is NOT a green', 
          text: { color: '#000000', size: '18px', position: 'top'}, 
          interactions: [],
          epoch: 1629683964000, data: null
        },
        {
          id: 2, folder_id: 1, resolution: '600', width: '800px', height: '600px',
          src: './assets/img/games/test2.jpg', name: 'Test 2', directions: 'Which one is NOT a lady bug?', 
          text: { color: '#000000', size: '18px', position: 'top'}, 
          interactions: [{id:1632269508066,show:true,text:"",color:"#000000",size:"18px",align:"center",type:"correct",width:173,height:459,x:"567",y:"35"}],
          epoch: 1629687964000, data: null
        },
        {
          id: 3, folder_id: 2, resolution: '600', width: '800px', height: '600px',
          src: './assets/img/games/test3.jpg', name: 'Test 3', 
          directions: 'Which one is NOT a circle', 
          text: { color: '#000000', size: '18px', position: 'top'}, 
          interactions: [],
          epoch: 1629681964000, data: null
        },
      ]
    };

    if (localStorage['sysData']) { //loda previous sysdata
      this.sysData = JSON.parse(localStorage['sysData']);
    }
  }



  alert(m) { this.presentAlert(m); }
  toast(m, c) { this.presentToast(m, c); }
  
  async presentAlert(m) {
    const alert = await this.alertController.create({
        header: 'Alert',
        subHeader: 'Message',
        message: m,
        buttons: ['OK']
    });

    await alert.present();
  }

  async presentToast(m, c) {
      const toast = await this.toastController.create({
          message: m,
          duration: 2000,
          color: c
      });
      toast.present();
  }


}
